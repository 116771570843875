<template>
  <div id="address">
    <div class="mine-title flex-center-start">
      {{$t('route.address')}}
      <div class="add-address flex" @click="addShow = true">
        <a-button class="add-btn flex" type="primary" ghost >
          <a-icon type="plus" />
        </a-button>
        <span>{{ $t('common.btn_3') }}</span>
      </div>
    </div>
    <ul class="address-list">
      <li v-for="(item, index) in addressList" :key="index">
        <div class="header flex-center-between">
          {{$t('mine.add_11')}} {{index+1}}
          <div class="radio" @click="defaultClick(item)">
            <input type="radio" :checked="item.is_default"/>
            {{$t('mine.add_12')}}
          </div>
        </div>
        <div class="address-cont flex-center-between">
          <div class="left">
            <div class="icon flex">{{item.consignee.substring(0,1)}}</div>
          </div>
          <div class="mid">
            <div class="name">
              <div class="item two-line-ellipsis" :title="item.consignee">
                <span class="label">{{$t('mine.add_13')}}</span>
                {{item.consignee}}
              </div>
              <div class="item two-line-ellipsis" :title="item.mobile">
                <span class="label">{{$t('mine.add_14')}}</span>
                {{item.mobile}}
              </div>
            </div>
            <div class="address">
              <div class="item two-line-ellipsis" :title="`${item.district} ${item.address}`">
                <span class="label">{{$t('mine.add_11')}}：</span>
                {{item.district}}{{item.address}}
              </div>
              <div class="item two-line-ellipsis" :title="item.postcode">
                <span class="label">{{$t('mine.add_15')}}</span>
                {{item.postcode}}
              </div>
            </div>
          </div>
          <div class="right">
            <span @click="delClick(item.address_id)">{{$t('mine.add_16')}}</span>
            <span class="colorBtn" @click="editClick(item.address_id)">{{$t('mine.add_17')}}</span>
          </div>
        </div>
      </li>
    </ul>
    <add-address :show="addShow" ref="edit" @childFn="addClick" @updata="getAddress"/>
  </div>
</template>
<script>
import AddAddress from "../components/AddAddress.vue";
import { message } from 'ant-design-vue';
export default {
  name:'Address',
  components: { AddAddress },
  data() {
    return {
      addShow: false,
      addressList:[]
    };
  },
  methods: {
    addClick(show) {
      this.addShow = show;
    },
    editClick(id){
      this.addShow = true
      this.$refs.edit.onEdit(id);
    },
    defaultClick(item){
      item.is_default = 1
      this.$http.post(this.APIURL.MYDATA.EDITADDRESS,
        item,false
      ).then(res=>{
        if(res.code==1){
          this.getAddress()
        }else{
          message.error(res.msg);
        }
      })
    },
    delClick(id){
      this.$http.post(this.APIURL.MYDATA.DELADDRESS,{
        address_id:id
      },false).then(res=>{
        if(res.code==1){
          message.success(res.msg);
          this.getAddress()
        }else{
          message.error(res.msg);
        }
      })
    },
    getAddress(){
      this.$http.post(this.APIURL.MYDATA.ADDRESSLIST,{},false).then(res=>{
        this.addressList = res.data.list
      })
    }
  },
  created(){
   this.getAddress()
  }
};
</script>
<style lang="scss" >

</style>